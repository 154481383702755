@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600&family=Poppins:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'YACgEZHPKsk-0', sans-serif;
}

@font-face {
  font-family: 'YACgEZHPKsk-0';
  src: url('./assets/fonts/BLACKBOYSONMOPEDS.ttf') format('truetype');
}

@layer base {
  h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 1.4;
    text-align: center;
    letter-spacing: 0.2em;
    color: #ffffff;
    @apply font-bold;

    @screen sm {
      font-size: 56px;
      line-height: 74px;
    }

    @screen lg {
      font-size: 100px;
      line-height: 100px;
    }
  }

  h2 {
    font-weight: 500;
    font-size: 36px;
    text-align: center;
    line-height: 1.37492552em;
    color: #000000;
    letter-spacing: 0.09em;

    @screen sm {
      font-size: 50px;
      line-height: 64px;
    }

    @screen lg {
      font-size: 64px;
      line-height: 80px;
      text-align: left;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 36px;
    line-height: 50px;
    text-align: left;
    color: #ffffff;

    @screen sm {
      font-size: 50px;
      line-height: 61px;
    }

    @screen lg {
      font-size: 55px;
      line-height: 72px;
    }
  }

  h4 {
    font-weight: 600;
    font-size: 15px;
    line-height: 1.33333333em;
    text-align: left;
    color: #000000;
    letter-spacing: 0.09em;

    @screen sm {
      font-size: 20px;
      line-height: 1.25;
    }

    @screen lg {
      font-size: 24px;
      line-height: 1.2;
    }
  }

  h5 {
    font-weight: 200;
    font-size: 15px;
    line-height: 1.33333333em;
    text-align: left;
    color: #000000;
    letter-spacing: 0.05em;

    @screen sm {
      font-size: 20px;
      line-height: 1.25;
    }

    @screen lg {
      font-size: 24px;
      line-height: 1.2;
    }
  }

  h6 {
    font-weight: 400;
    text-align: center;
    font-size: 16px;
    line-height: 1.39603352em;
    color: #737373;
  }
}

.break-words {
  word-break: break-word;
  overflow-wrap: anywhere;
}



